import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom'; // Importa Link
import TablaProductos from '../utils/TablaProductos'; // Asegúrate de que la ruta sea correcta

const Dashboard = () => {
  const [tokenInfo, setTokenInfo] = useState(null);
  const [data, setData] = useState([]);
 
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setTokenInfo(decoded);

        // Obtener productos guardados para este usuario
        fetchProductos(decoded.user.usuario);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }
  }, []);

  const fetchProductos = async (usuario_creador) => {
    try {
      const response = await axios.get(`https://apiwooexport.agenciaadhoc.com/productos/${usuario_creador}`);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los productos:', error);
    }
  };

  const handleInputChange = async (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...data];
    
    // Si el campo es un precio, convierte el valor
    if (name === 'precio_rebajado' || name === 'precio_normal') {
        updatedData[index][name] = value.replace(',', '.');
    } else {
        updatedData[index][name] = value;
    }
    
    setData(updatedData);

    try {
      const response = await axios.put(`https://apiwooexport.agenciaadhoc.com/editar-producto/${updatedData[index].id}`, updatedData[index]);
      console.log('Producto actualizado:', response.data);
    } catch (error) {
      console.error('Error al actualizar el producto:', error);
    }
};


  const handleSaveRow = async (producto) => {
    try {
      const response = await axios.post('https://apiwooexport.agenciaadhoc.com/crear-producto', producto);
      console.log('Producto guardado:', response.data);
      setData((prevData) => [...prevData, response.data]);
    } catch (error) {
      console.error('Error al guardar el producto:', error);
    }
  };

  const handleAddRow = () => {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const newRow = {
      usuario_creador: decoded.user.usuario,
      tipo: '',
      sku: '',
      nombre: '',
      publicado: 1,
      destacado: 0,
      visibilidad: '',
      descripcion_corta: '',
      descripcion: '',
      existencias: 1,
      inventario: 0,
      cantidad_bajo_inventario: 0,
      permitir_reservas: 0,
      vendido_individualmente: 0,
      peso: 0.0,
      longitud: 0.0,
      anchura: 0.0,
      altura: 0.0,
      precio_rebajado: 0.0,
      precio_normal: 0.0,
      categorias: '',
      etiquetas: '',
      imagenes: '',
    };

    handleSaveRow(newRow);
  };

  const handleSimple = () => {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const newRow = {
      usuario_creador: decoded.user.usuario,
      tipo: 'simple',
      sku: 'PROD_S',
      nombre: '',
      publicado: 1,
      destacado: 0,
      visibilidad: 'visible',
      descripcion_corta: '',
      descripcion: '',
      existencias: 1,
      inventario: 0,
      cantidad_bajo_inventario: 0,
      permitir_reservas: 0,
      vendido_individualmente: 0,
      peso: 0.0,
      longitud: 0.0,
      anchura: 0.0,
      altura: 0.0,
      precio_rebajado: 0.0,
      precio_normal: 0.0,
      categorias: '',
      etiquetas: '',
      imagenes: '',
    };

    handleSaveRow(newRow);
  };

  const handleAddVariableProduct = () => {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const newRow = {
      usuario_creador: decoded.user.usuario,
      tipo: 'variable',
      sku: 'PROD1',
      nombre: '',
      publicado: 1,
      destacado: 0,
      visibilidad: 'visible',
      descripcion_corta: '',
      descripcion: '',
      existencias: 1,
      inventario: 0,
      cantidad_bajo_inventario: 0,
      permitir_reservas: 0,
      vendido_individualmente: 0,
      peso: 0.0,
      longitud: 0.0,
      anchura: 0.0,
      altura: 0.0,
      precio_rebajado: 0.0,
      precio_normal: 0.0,
      categorias: '',
      etiquetas: '',
      imagenes: '',
    };

    handleSaveRow(newRow);
  };

  const handleVariation = () => {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const newRow = {
      usuario_creador: decoded.user.usuario,
      tipo: 'variation',
      sku: 'PROD1-ROJO',
      nombre: '',
      publicado: 1,
      destacado: 0,
      visibilidad: 'visible',
      descripcion_corta: '',
      descripcion: '',
      existencias: 1,
      inventario: 0,
      cantidad_bajo_inventario: 0,
      permitir_reservas: 0,
      vendido_individualmente: 0,
      peso: 0.0,
      longitud: 0.0,
      anchura: 0.0,
      altura: 0.0,
      precio_rebajado: 0.0,
      precio_normal: 0.0,
      categorias: '',
      etiquetas: '',
      imagenes: '',
    };

    handleSaveRow(newRow);
  };

  const handleRemoveRow = async (index) => {
    const productoId = data[index].id;
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);

    try {
      await axios.delete(`https://apiwooexport.agenciaadhoc.com/eliminar-producto/${productoId}`);
      console.log('Producto eliminado');
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  const handleRemoveAllRows = async () => {
    for (const producto of data) {
      try {
        await axios.delete(`https://apiwooexport.agenciaadhoc.com/eliminar-producto/${producto.id}`);
      } catch (error) {
        console.error('Error al eliminar el producto:', error);
      }
    }
    setData([]);
  };

  function logOut(){
    localStorage.removeItem('token');

  }

  return (
    <div className='site-width'>
      {tokenInfo ? (
        <>
          {tokenInfo.user.rol === 'admin' && (
            <>
            <Link to="/administracion">
              <button className='boton-admin'>Administrar Usuarios</button>
            </Link>
            </>
            
          )}
            <Link to="/">
              <button onClick={logOut} className='boton-logout'>Cerrar Sesión</button>
            </Link>
       
          

          { tokenInfo && 
            <>
              {tokenInfo.user.estado === "inactivo" ? (
                <div className='usuario-desactivado'>
                  <img src="/images/visibilidad.svg" alt="Ojo" />
                  <h1>Este usuario está desactivado. Contacte con nuestros desarrolladores.</h1>  
                </div>
              ) : ( 
                <>
                   <h1>WooExport de {tokenInfo.user.nombre}</h1>
                   <p>A continuación, podrás crear tu plantilla previa para una importación nativa a WooCommerce.</p>
                  <TablaProductos
                  data={data}
                  handleInputChange={handleInputChange}
                  handleAddRow={handleAddRow}
                  handleRemoveRow={handleRemoveRow}
                  handleRemoveAllRows={handleRemoveAllRows}
                  handleSimple={handleSimple}
                  handleVariation={handleVariation}
                  handleAddVariableProduct={handleAddVariableProduct}
                />
                <CSVLink data={data} filename={tokenInfo.user.usuario+`-adhoc-wooexport.csv`}>
                <button className='csv-export'>Exportar a CSV</button>
              </CSVLink>
              </>
              )}
            </>
          }


    
        </>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default Dashboard;
