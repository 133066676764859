import React, { useRef } from 'react';

const TablaProductos = ({ data, handleInputChange, handleAddRow, handleRemoveRow, handleRemoveAllRows, handleAddVariableProduct, handleVariation, handleSimple }) => {
  const fields = [
    { key: 'tipo', label: 'Tipo', description: 'Define el tipo de producto: simple, variable, grouped o external.' },
    { key: 'sku', label: 'SKU', description: 'Identificador único para el producto. Debe ser único. En caso de producto variable, importe las bases y fíjese como debería configurar el SKU para vincular las variaciones.' },
    { key: 'nombre', label: 'Nombre', description: 'Nombre del producto. Máximo 255 caracteres.' },
    { key: 'publicado', label: 'Publicado', description: '1 para publicado, 0 para no publicado.' },
    { key: 'destacado', label: '¿Está destacado?', description: '1 para destacar el producto, 0 para no.' },
    { key: 'visibilidad', label: 'Visibilidad en el catálogo', description: 'Controla dónde aparece: visible, catalogo, busqueda, oculto.' },
    { key: 'descripcion_corta', label: 'Descripción corta', description: 'Breve resumen del producto (máx. 160 caracteres).' },
    { key: 'descripcion', label: 'Descripción', description: 'Descripción detallada del producto. Sin límite de caracteres.' },
    { key: 'existencias', label: '¿Existencias?', description: '1 para gestionar inventario, 0 para no gestionar.' },
    { key: 'inventario', label: 'Inventario', description: 'Cantidad actual en stock. Debe ser un número entero.' },
    { key: 'cantidad_bajo_inventario', label: 'Cantidad de bajo inventario', description: 'Umbral para alertas de bajo stock. Número entero.' },
    { key: 'permitir_reservas', label: '¿Permitir reservas de productos agotados?', description: '1 para permitir reservas, 0 para no permitir.' },
    { key: 'vendido_individualmente', label: '¿Vendido individualmente?', description: '1 para limitar a una unidad por pedido, 0 para no.' },
    { key: 'peso', label: 'Peso (kg)', description: 'Peso del producto en kilogramos. Use decimales.' },
    { key: 'longitud', label: 'Longitud (cm)', description: 'Longitud en centímetros. Use valores numéricos.' },
    { key: 'anchura', label: 'Anchura (cm)', description: 'Ancho en centímetros. Use valores numéricos.' },
    { key: 'altura', label: 'Altura (cm)', description: 'Altura en centímetros. Use valores numéricos.' },
    { key: 'precio_rebajado', label: 'Precio rebajado', description: 'Precio con descuento. Use el formato decimal "9,90".' },
    { key: 'precio_normal', label: 'Precio normal', description: 'Precio original. Use el formato decimal "9,90".' },
    { key: 'categorias', label: 'Categorías', description: 'Categorías separadas por comas. Ejemplo: "Ropa,Hombres,Camisetas".' },
    { key: 'etiquetas', label: 'Etiquetas', description: 'Palabras clave separadas por comas. Ejemplo: "algodón,verano,moda".' },
    { key: 'imagenes', label: 'Imágenes', description: 'URL de la imagen principal. Para múltiples imágenes, sepárelas con comas.' },
  ];

  const tableContainerRef = useRef(null);

  const handleScroll = (event) => {
    const container = tableContainerRef.current;
    if (container) {
      // Verifica si se puede desplazar horizontalmente
      if (container.scrollWidth > container.clientWidth) {
        container.scrollLeft += event.deltaY;
        event.preventDefault(); // Previene el desplazamiento vertical de la página
      }
    }
  };

  return (
    <div className='tabla-wooexport'>
      <div className='grid-inserciones'>
        <div className='insercion'>
          <h3>Producto Simple</h3>
          <p>Inserta una base para añadir un producto simple (sin ningún tipo de variación).</p>
          <button onClick={handleSimple}>Inserta Producto Simple</button>
        </div>
        <div className='insercion'>
          <h3>Producto Variable</h3>
          <p>Inserta una base para añadir un producto variable (padre e hijo).</p>
          <button onClick={handleAddVariableProduct}>Inserta P.V Padre</button>
          <button onClick={handleVariation}>Inserta P.V Hijo</button>
        </div>
        <div className='insercion'>
          <h3>Eliminar Todo</h3>
          <p>Elimina todas las filas añadidas a la tabla.</p>
          <button onClick={handleRemoveAllRows}>Eliminar Todas las Filas</button>
        </div>
      </div>
      <div className="wooexport-intro">
      <h3>MIS PRODUCTOS</h3>
      <p>Con esta tabla completada, ayudarás al desarrollador encargado de tu página web a importar todos tus productos de manera secuencial y controlada.</p>
      </div>
      <div 
        className='overflow-woo' 
        onWheel={handleScroll} 
        style={{ overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap' }} // Asegura que el scroll sea horizontal
      >
        <table className='wootable'>
          <thead>
            <tr>
              <th>Acciones</th>
              {fields.map((field) => (
                <th key={field.key}>
                  <div className="tooltip">
                    {field.label} <img src="/images/informacion.svg" alt="Info" />
                    <span className="tooltiptext">{field.description}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={row.id || index}>
                <td className='eliminar-fila'>
                  <button onClick={() => handleRemoveRow(index)}>Eliminar</button>
                </td>
                {fields.map((field) => (
                  <td key={field.key}>
                    <input
                      type="text"
                      name={field.key}
                      value={row[field.key]}
                      onChange={(event) => handleInputChange(index, event)}
                      readOnly={field.key === 'tipo'} // Bloquea el campo 'tipo'
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaProductos;
