import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const RutaProtegida = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        // Promesa para la verificación del token
        const verifyPromise = axios.get('https://apiwooexport.agenciaadhoc.com/comprobartoken', {
          headers: {
            'adhoc-token': token
          }
        });

        // Promesa para el retraso de 3 segundos
        const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));

        // Espera que ambas promesas se resuelvan
        const [response] = await Promise.all([verifyPromise, delayPromise]);

        if (response.data.auth) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error al verificar el token:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, []);

  if (isLoading) {
    return (
      <div className='loader-grid'>
        <div className="loader"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    // Si el token no es válido, redirige a la página de inicio de sesión
    return <Navigate to="/" />;
  }

  // Si el token es válido, renderiza el componente hijo
  return children;
};

export default RutaProtegida;
