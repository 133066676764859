import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js'; // Importa la librería crypto-js

const Administracion = () => {
  const [tokenInfo, setTokenInfo] = useState(null);
  const [formData, setFormData] = useState({
    usuario: '',
    nombre: '',
    apellidos: '',
    email: '',
    contrasena: '',
    rol: 'usuario',
    estado: 'activo'
  });
  const [usuarios, setUsuarios] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setTokenInfo(decoded);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (tokenInfo) {
      console.log('Decoded token info:', tokenInfo);
    }
  }, [tokenInfo]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Genera el hash MD5 de la contraseña
    const hashedPassword = CryptoJS.MD5(formData.contrasena).toString();

    // Crea un nuevo objeto de datos del formulario con la contraseña hasheada
    const formDataWithHashedPassword = {
      ...formData,
      contrasena: hashedPassword,
    };

    try {
      const response = await axios.post('https://apiwooexport.agenciaadhoc.com/crear-usuario', formDataWithHashedPassword);
      setFormData({
        usuario: '',
        nombre: '',
        apellidos: '',
        email: '',
        contrasena: '',
        rol: 'usuario',
        estado: 'activo'
      });
      setUsuarios([...usuarios, response.data]);
    } catch (error) {
      console.error('Error al crear el usuario:', error);
    }
  };

  useEffect(() => {
    const obtenerUsuarios = async () => {
      try {
        const response = await axios.get('https://apiwooexport.agenciaadhoc.com/obtener-usuarios');
        setUsuarios(response.data);
      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
      }
    };

    obtenerUsuarios();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEstadoChange = async (id, nuevoEstado) => {
    try {
      const response = await axios.put(`https://apiwooexport.agenciaadhoc.com/actualizar-usuario/${id}`, { estado: nuevoEstado });
      setUsuarios(usuarios.map(usuario => 
        usuario.id === id ? { ...usuario, estado: nuevoEstado } : usuario
      ));
    } catch (error) {
      console.error('Error al actualizar el estado del usuario:', error);
    }
  };

  const handleEliminarUsuario = async (id) => {
    try {
      await axios.delete(`https://apiwooexport.agenciaadhoc.com/eliminar-usuario/${id}`);
      setUsuarios(usuarios.filter(usuario => usuario.id !== id));
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  const filteredUsuarios = usuarios.filter((usuario) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      usuario.nombre.toLowerCase().includes(lowerSearchTerm) ||
      usuario.email.toLowerCase().includes(lowerSearchTerm)
    );
  });

  return (
    <div className='site-width'>
      {tokenInfo &&
        <>
         <Link to="/dashboard"><button class="boton-admin">Volver a WooExport</button></Link>
          {tokenInfo.user.rol === "admin" ? (
            <>
              <div className='intro-admin'>
                <h1>Administración</h1>
                <p>Crea y gestiona usuarios que tienen acceso a WooExport.</p>
              </div>
              <div className='grid-administracion'>
                <form onSubmit={handleSubmit}>
                  <h2>Crear un nuevo usuario</h2>
                  <div className="input-form">
                    <label htmlFor="usuario">Usuario</label>
                    <input type="text" name="usuario" placeholder="Usuario" value={formData.usuario} onChange={handleChange} required />
                  </div>
                  <div className="input-form">
                    <label htmlFor="nombre">Nombre</label>
                    <input type="text" name="nombre" placeholder="Nombre" value={formData.nombre} onChange={handleChange} required />
                  </div>
                  <div className="input-form">
                    <label htmlFor="apellidos">Apellidos</label>
                    <input type="text" name="apellidos" placeholder="Apellidos" value={formData.apellidos} onChange={handleChange} required />
                  </div>
                  <div className="input-form">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                  </div>
                  <div className="input-form">
                    <label htmlFor="contrasena">Contraseña</label>
                    <input type="password" name="contrasena" placeholder="Contraseña" value={formData.contrasena} onChange={handleChange} required />
                  </div>
                  <div className="input-form">
                    <label htmlFor="rol">Permisos</label>
                    <select name="rol" value={formData.rol} onChange={handleChange}>
                      <option value="usuario">Usuario</option>
                      <option value="admin">Admin</option>
                    </select>                  
                  </div>
                  <div className="input-form">
                    <label htmlFor="estado">Estado de la cuenta</label>
                    <select name="estado" value={formData.estado} onChange={handleChange}>
                      <option value="activo">Activo</option>
                      <option value="inactivo">Inactivo</option>
                    </select>             
                  </div>
                  <button type="submit">Crear</button>
                </form>
                <div className='lista-usuarios'>
                  <h2>Lista de Usuarios</h2>
                  <input 
                    type="text" 
                    placeholder="Buscar por nombre o correo electrónico..." 
                    value={searchTerm} 
                    onChange={handleSearchChange}
                  />
                  <div className='grid-usuarios'>
                    {filteredUsuarios.map((usuario) => (
                      <div className={`usuario-listado ` + usuario.rol} key={usuario.id}>
                        <p>{usuario.nombre} {usuario.apellidos} - {usuario.email} ({usuario.rol})</p>
                        <div className="usuario-actions">
                          <select
                            value={usuario.estado}
                            onChange={(e) => handleEstadoChange(usuario.id, e.target.value)}
                          >
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                          </select>
                          <button onClick={() => handleEliminarUsuario(usuario.id)}>Eliminar</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>No tienes permisos de administrador.</p>
          )}
        </>
      }
    </div>
  );
};

export default Administracion;
