import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const Home = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Resetea el error

    // Genera el hash MD5 de la contraseña
    const hashedPassword = CryptoJS.MD5(password).toString();

    try {
      const response = await axios.post('https://apiwooexport.agenciaadhoc.com/login', {
        usuario: username,
        contrasena: hashedPassword
      });

      // Si la solicitud es exitosa
      localStorage.setItem('token', response.data.token); // Guarda el token en localStorage
      navigate('/dashboard'); // Redirige al dashboard
      console.log(response)
    } catch (error) {
      // Si hay un error en la solicitud
      if (error.response) {
        // El servidor respondió con un estado diferente de 2xx
        console.log(error)
        setError(error.response.data || 'Usuario o contraseña incorrectos.');
      } else {
        // Error en la configuración de la solicitud
        console.error('Error en la solicitud de inicio de sesión:', error.message);
        setError('Error en la solicitud de inicio de sesión. Intenta de nuevo más tarde.');
      }
    }
  };

  return (
    <div className='login'>
      <form onSubmit={handleLogin}>
        <div className="intro-login">
          <div className="tool-logo">
            <img src="/images/logowooblue.svg" alt="Logo Woo" />
            <h1>Exporter</h1>
          </div>
          <p>Inicia sesión para acceder al exportador de productos manuales de Agencia Adhoc.</p>
        </div>
        <div className='input-data'>
          <label>Usuario</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className='input-data'>
          <label>Contraseña</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className='error'>{error}</p>}
        <button type="submit">Acceder</button>
      </form>
    </div>
  );
};

export default Home;
